import { styled, alpha } from "@mui/material/styles";
import { Box } from "@mui/material";
import { ImgBox } from "views/common/components/UI";

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),

  [theme.breakpoints.down("lg")]: {
    marginBottom: theme.spacing(3),
  },

  "& .VCard": {
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.05)",
    transition: "box-shadow 0.3s ease",

    [theme.breakpoints.down("xl")]: {
      overflow: "visible",
      position: "relative",
    },
    "& .VCardWrapper": {
      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
      },
    },
    "& .MuiCardContent-root": {
      padding: 0,
      display: "flex",
      width: "100%",
      height: "100%",
      "& .VCPrice": {
        minWidth: theme.spacing(20),
        borderLeft: `1px solid ${theme.palette.grey[100]}`,
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
      },
    },
    "&:hover": {
      boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.15)",
    },
    "& .VCActions": {
      borderTop: `1px solid ${theme.palette.grey[100]}`,
      padding: theme.spacing(1.5, 3),
      justifyContent: "flex-end",
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      alignItems: "center",

      "& .VCActionsWrapper": {
        display: "flex",
        gap: theme.spacing(1.5),
      },
      [theme.breakpoints.down("xl")]: {
        justifyContent: "flex-end",
        padding: 0,
        borderTop: 0,
      },

      "& .VCActionsButton": {
        minHeight: theme.spacing(4.56),
        minWidth: "auto",
        [theme.breakpoints.down("md")]: {
          "& .VCActionsButtonText": {
            display: "none",
          },
          "& .MuiButton-icon": {
            margin: 0,
          },
        },
      },
    },
    "& .VCIcon": {
      color: theme.palette.grey[300],
      fontSize: theme.typography.pxToRem(16),
    },
    "& .VCLabelText": {
      color: theme.palette.secondary.light,
    },
    "& .VCDigit": {
      display: "flex",
      justifyConten: "center",
      alignItems: "center",
      padding: theme.spacing(0, 0.3),
      marginRight: theme.spacing(0.5),
      backgroundColor: `${alpha(theme.palette.primary.light, 0.2)}`,
      color: theme.palette.secondary.dark,
      borderRadius: 0.5,
    },
    "& .VCChip": {
      backgroundColor: theme.palette.primary.A000,
      color: theme.palette.primary.main,
      fontWeight: "bold",
      padding: theme.spacing(0.25, 1),
      height: theme.spacing(2.25),
      fontSize: theme.typography.pxToRem(14),
      "& .VCChipIcon": {
        color: theme.palette.primary.main,
        margin: 0,
        fontSize: theme.typography.pxToRem(14),
      },
      "& .MuiChip-label": { paddingRight: 0, paddingLeft: theme.spacing(0.5) },
    },
    "& .VCLocationColor": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up("md")]: {
        columnGap: theme.spacing(4),
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
      },
    },
    "& .VCGrid": {
      rowGap: theme.spacing(1),
      flexDirection: "row",
      alignItems: "center",
      flexWrap: "wrap",
      columnGap: theme.spacing(1),
    },
  },
  "& .MuiButtonBase-root ": {
    height: "100%",
  },
  "& .VCActionsAndPriceDestop": {
    [theme.breakpoints.down("xl")]: {
      borderTop: `1px solid ${theme.palette.grey[100]}`,
      display: "flex",
      alignItems: "center",
      justifyContent: "spaceBetween",
      padding: theme.spacing(1.5, 2),
    },
  },
  "& .VCUnderLine": {
    textDecoration: "underline",
  },
}));

export const StyledImgBox = styled(ImgBox)(({ theme }) => ({
  "&.IBimgbox": {
    "& img": {
      objectPosition: "top",
      height: "auto",
      position: "relative !important",
    },
  },
}));
export default StyledBox;
