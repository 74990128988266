import React from "react";
import StyledBox, {
  StyledImgBox,
} from "views/HomeView/components/HeroSection/HeroSection.styles";
import { ImgBox, Typography500 } from "views/common/components/UI";
import { useTranslation } from "next-i18next";
import {
  Box,
  Container,
  Stack,
  Button,
  Divider,
  FormControlLabel,
  Checkbox,
  Typography,
  TextField,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import NavigatorLink from "views/common/components/Logical/NavigatorLink/NavigatorLink";
import PATHS from "lib/navigation/paths";
import heroImage from "public/images/hero-image.webp";
import erLogo from "public/images/er-logo.png";
import harleyLogo from "public/images/harley-davidson-logo.png";
import erdxLogo from "public/images/erdx-logo.png";

const HeroSection = (props) => {
  const { t } = useTranslation("home");
  const {
    handelSignInBtnClick,
    handleInputChange,
    formData,
    handleRememberMeCheckBox,
    handleForgetPassClickBtn,
    phone,
  } = props;

  const renderForgetPassAndRemember = () => {
    return (
      <Stack direction="row" mt={1.5} className="HSForgetAndRemberContainer">
        <FormControlLabel
          className="HSCheckbox"
          control={
            <Checkbox
              checked={formData.remember_me}
              name="remember_me"
              onChange={handleRememberMeCheckBox}
            />
          }
          label={t("home:remember_me")}
        />
        <NavigatorLink onClick={handleForgetPassClickBtn} color="common.white">
          <Typography
            component="p"
            variant="body1"
            className="HSForgetPassLink"
          >
            {t("home:forgot_password")}
          </Typography>
        </NavigatorLink>
      </Stack>
    );
  };

  return (
    <StyledBox position="relative">
      <Box className="HSImgContainer">
        <ImgBox
          image={{
            src: heroImage,
            alt: t("home:erdx_logo"),
          }}
          imgheight="700px"
          imgwidth="100%"
          objectFit="cover"
          priority
          placeholder="blur"
          blurDataURL={undefined}
          unoptimized={false}
        />
      </Box>
      <Container maxWidth="xl" className="HSContainer">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={3}
          my={4}
        >
          <Stack direction="row" spacing={3} className="HSBrandLogo">
            <ImgBox
              className="HSBrandLogos"
              image={{
                src: erLogo,
                alt: t("home:erdx_logo"),
              }}
              imgheight="80px"
              imgwidth="100px"
              objectFit="contain"
              unoptimized
              priority
            />
            <ImgBox
              className="HSBrandLogos"
              image={{
                src: harleyLogo,
                alt: t("home:erdx_logo"),
              }}
              imgheight="80px"
              imgwidth="100px"
              objectFit="contain"
              unoptimized
            />
          </Stack>
          <Stack direction="row" spacing={1.5}>
            <Button
              component={NavigatorLink}
              variant="text"
              startIcon={<PersonIcon />}
              className="HSLinks"
              href={PATHS.signup()}
            >
              {t("home:sign_up")}
            </Button>
            <Divider orientation="vertical" className="HSDivider" flexItem />
            <Button
              component={NavigatorLink}
              variant="text"
              startIcon={<PhoneIcon />}
              className="HSLinks HSPhone"
              href={`tel:${phone}`}
            >
              {phone}
            </Button>
          </Stack>
        </Stack>
        <Stack alignItems="center" justifyContent="center" spacing={3} py={10}>
          {" "}
          <StyledImgBox
            image={{
              src: erdxLogo,
              alt: t("home:erdx_logo"),
            }}
            imgheight="66px"
            imgwidth="400px"
            objectFit="contain"
          />
          <Typography500 variant="h4" component="p" className="HSTitle">
            {t("home:hero_section_title")}
          </Typography500>
          <Box className="HSSignFormContainer">
            <Stack
              direction={{ sm: "column", lg: "row" }}
              className="HSSignForm"
              p={2}
              spacing={3}
            >
              <TextField
                className="HSTextField"
                required
                fullWidth
                label={t("home:email_address")}
                variant="filled"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
              <TextField
                className="HSTextField"
                required
                fullWidth
                label={t("home:password")}
                variant="filled"
                name="password"
                type="password"
                value={formData.password}
                onChange={handleInputChange}
              />
              <Stack>
                <Button
                  size="large"
                  variant="contained"
                  fullWidth
                  className="HSSignFormAction"
                  onClick={handelSignInBtnClick}
                >
                  {t("home:sign_me_in")}
                </Button>
              </Stack>
              <Box className="showTABLET">{renderForgetPassAndRemember()}</Box>
            </Stack>
            <Box className="showDESKTOP">{renderForgetPassAndRemember()}</Box>
          </Box>
        </Stack>
      </Container>
    </StyledBox>
  );
};

export { HeroSection };
