import React from "react";
import StyledBox from "views/HomeView/components/AboutSection/AboutSection.styles";
import { useTranslation } from "next-i18next";
import { Container, Typography } from "@mui/material";
import { Typography700 } from "views/common/components/UI";

const AboutSection = () => {
  const { t } = useTranslation("home");

  return (
    <StyledBox textAlign="center">
      <Container maxWidth="xl">
        <Typography700 variant="h3" component="h2" color="primary" mb={6}>
          {t("home:about_ragleRider_dealer_exchange")}
        </Typography700>
        <Typography variant="body1" component="p">
          {t("home:about_ragleRider_dealer_exchange_dis")}
        </Typography>
      </Container>
    </StyledBox>
  );
};

export { AboutSection };
