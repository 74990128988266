import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[700],
  "& .FWrapper": {
    color: theme.palette.common.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(4),
    padding: theme.spacing(1.5, 0),
    "& .MuiLink-root": {
      color: theme.palette.common.white,
      textDecorationColor: theme.palette.grey[50],
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      padding: theme.spacing(2),
      gap: theme.spacing(2),
      textAlign: "center",
      "& .FText": {
        fontSize: theme.typography.pxToRem(12),
      },
    },
  },
}));

export default StyledBox;
