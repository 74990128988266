import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  minHeight: theme.spacing(6),
  paddingRight: theme.spacing(2.5),
  paddingLeft: theme.spacing(2.5),
  fontSize: theme.typography.pxToRem(14),
  textTransform: "capitalize",
  "@media only screen and (max-width: 1024px)": {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(12),
  },
  "& svg.MuiSvgIcon-root": {
    fontSize: theme.typography.pxToRem(18),
    "@media only screen and (max-width: 1024px)": {
      fontSize: theme.typography.pxToRem(16),
    },
  },
}));

export default StyledButton;
