import { styled } from "@mui/material/styles";
import { Drawer } from "@mui/material";
import { MenuItemSelect } from "views/common/components/UI/TopBar/MenuItemSelect/MenuItemSelect";

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: theme.spacing(44),
    backgroundColor: "#303030",
    color: theme.palette.common.white,
    "& .UDDrawerHeader": {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1.5),
      backgroundColor: theme.palette.grey[700],
      minHeight: theme.spacing(10),
      "& .UDCloseIcon": {
        color: theme.palette.common.white,
      },
      "& .UDrHeaderUserInfo": {
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        "& .UDHeaderAvatar": {
          backgroundColor: theme.palette.secondary.main,
        },
        "& .UDHeaderAvatarInfo": {
          margin: theme.spacing(0, 2.5),
          maxWidth: theme.typography.pxToRem(160),
          color: theme.palette.common.white,
          "& .UDCaption": {
            fontSize: theme.typography.pxToRem(10),
            fontWeight: 300,
            textTransform: "uppercase",
          },
        },
      },
    },
    "& .UDUserMenu": {
      padding: 0,
      "& .UDListItem": {
        boxShadow: "0px 1px 0px 0px rgba(0, 0, 0, 0.16)",
        minHeight: theme.spacing(7),

        "& .MuiButtonBase-root": {
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          alignItems: "center",
          "& .MuiListItemIcon-root": {
            color: theme.palette.common.white,
            display: "flex",
            alignItems: "center",
            width: theme.spacing(2),
            minWidth: theme.spacing(2),
            height: theme.spacing(2),
            marginRight: theme.spacing(1),
            "& svg": {
              fontSize: theme.typography.pxToRem(16),
            },
          },
          "& .UDListItemText": {
            marginTop: 0,
            marginBottom: 0,
            "& .MuiListItemText-primary": {
              fontSize: theme.typography.pxToRem(14),
              lineHeight: theme.typography.pxToRem(24),
              fontWeight: 500,
            },
            "& .MuiListItemText-secondary": {
              fontSize: theme.typography.pxToRem(12),
              lineHeight: theme.typography.pxToRem(16),
              color: theme.palette.grey[200],
            },
          },
        },
      },
    },
  },
}));

export const StyledMenuItemSelect = styled(MenuItemSelect)(({ theme }) => ({
  "& .MISLabel": {
    padding: theme.spacing(0.5, 2, 0),
  },
  "& .MISSelect": {
    "& .MuiSelect-select": {
      paddingLeft: `${theme.spacing(4.5)} !important`,
      fontSize: theme.typography.pxToRem(12),
      color: theme.palette.grey[200],
    },
  },
  "& .MISIcon": {
    marginRight: `${theme.spacing(1)} !important`,
  },
}));

export default StyledDrawer;
