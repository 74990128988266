import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledBox = styled(Box)(({ theme }) => ({
  "& .TPAppBar": {
    boxShadow: "none",
    alignItems: "flex-end",
    backgroundColor: theme.palette.grey[700],
    zIndex: "1100",
    padding: theme.spacing(1, 0),
    "& .MuiToolbar-root": {
      minHeight: "auto",
      backgroundColor: theme.palette.grey[700],
      zIndex: "1100",
      padding: 0,
    },
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(2, 0),
    },
    "& .MBHomeLink": {
      fontSize: theme.typography.pxToRem(19),
      textTransform: "uppercase",
      fontWeight: 700,
      color: theme.palette.common.white,
      textDecoration: "none",
      [theme.breakpoints.down("md")]: {
        fontSize: theme.typography.pxToRem(16),
      },
    },
    "& .TBCart": {
      borderRight: `1px solid ${theme.palette.grey[400]}`,
      padding: theme.spacing(0, 2),
      [theme.breakpoints.up("lg")]: {
        borderLeft: `1px solid ${theme.palette.grey[400]}`,
      },
      "& .TBCartIconButton": {
        padding: 0,
      },
      "& .MuiBadge-badge": {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        fontWeight: 500,
        top: 3,
        right: 3,
      },
      "& .TBCartIcon": {
        color: theme.palette.grey[50],
        fontSize: theme.typography.pxToRem(28),
        [theme.breakpoints.up("md")]: {
          height: theme.spacing(2),
          width: theme.spacing(2),
          minWidth: theme.spacing(2),
        },
        [theme.breakpoints.up("md")]: {
          fontSize: theme.typography.pxToRem(30),
        },
      },
    },
  },
  "& .TBLogos": {
    position: "absolute",
    top: theme.spacing(1),
    gap: theme.spacing(3),
    display: "flex",

    "& .TBLogo": {
      height: "auto",
    },
  },
  "& .TBUserIcon": {
    padding: 0,
    color: theme.palette.grey[50],
  },
  "& .UDButton": {
    backgroundColor: theme.palette.grey[600],
    color: theme.palette.common.white,
    textTransform: "uppercase",
    boxShadow: "0px 1px 0px 0px rgba(0, 0, 0, 0.16)",
    borderRadius: theme.spacing(2.5, 0.25, 0.25, 2.5),
    padding: theme.spacing(0.5, 1.5, 0.5, 0),
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
    "& .UDAvatar": {
      width: theme.spacing(5),
      height: theme.spacing(5),
      boxShadow: `0 0 0 7px ${theme.palette.grey[700]}`,
    },
    "& .UDUserInfo": {
      margin: "0.1rem 0.75rem 0 0.75rem",
      lineHeight: 1,
      textAlign: "left",
      maxWidth: `${theme.spacing(32)} !important`,
      "& .UDCaption": {
        fontSize: theme.typography.pxToRem(10),
        fontWeight: 300,
        textTransform: "uppercase",
      },
      "& .UDUserName": {
        textTransform: "capitalize",
        whiteSpace: "nowrap",
      },
    },
  },
}));

export default StyledBox;
