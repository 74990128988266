import React from "react";
import StyledBox, {
  StyledImgBox,
} from "views/HomeView/components/MotorcycleAvailabilitySection/MotorcycleAvailabilitySection.styles";
import { useTranslation } from "next-i18next";
import { Container, Box, Typography, Stack } from "@mui/material";
import { Typography700 } from "views/common/components/UI";
import Grid from "@mui/material/Grid2";

const MotorcycleAvailabilitySection = () => {
  const { t } = useTranslation("home");
  const motorcycleAvailabilityItem = [
    {
      number: "1",
      title: t("home:participating_hosting"),
      text: t("home:participating_hosting_text"),
    },
    {
      number: "2",
      title: t("home:all_participating_dealers"),
      text: t("home:all_participating_dealers_text"),
    },
    {
      number: "3",
      title: t("home:all_dealers_for_the_next_30days"),
      text: t("home:all_dealers_for_the_next_30days_text"),
    },
    {
      number: "4",
      title: t("home:bikes_go_to_auction"),
    },
  ];
  return (
    <StyledBox>
      <Container maxWidth="xl">
        <Grid container spacing={{ lg: 3, xl: 6 }} alignItems="center">
          <Grid size={{ xs: 12, md: 12, lg: 6 }} className="MASImageGrid">
            <Box className="MASImage">
              <StyledImgBox
                image={{
                  src: "/erdx/images/erdx-motorcycle_availability.png",
                  alt: t("home:erdx_logo"),
                }}
                imgheight="650px"
                imgwidth="100%"
                objectFit="cover"
                unoptimized
              />
            </Box>
          </Grid>
          <Grid size={{ xs: 12, md: 12, lg: 6 }}>
            <Box mb={8}>
              <Typography700 variant="h3" component="h2" color="primary" mb={2}>
                {t("home:motorcycle_availability")}
              </Typography700>
              <Typography700 variant="h4" component="p">
                {t("home:how_are_bikes_offered")}
              </Typography700>
            </Box>
            <Stack gap={4}>
              {motorcycleAvailabilityItem.map((item, index) => (
                <Stack direction="row" gap={2} key={index}>
                  <Typography700
                    className="MASItemNumber"
                    component="p"
                    variant="h4"
                  >
                    {item.number}
                  </Typography700>
                  <Box>
                    <Typography700
                      component="p"
                      variant="h4"
                      mb={1}
                      dangerouslySetInnerHTML={{
                        __html: item.title,
                      }}
                    ></Typography700>
                    <Typography component="p" variant="body1">
                      {item.text}
                    </Typography>
                  </Box>
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </StyledBox>
  );
};

export { MotorcycleAvailabilitySection };
