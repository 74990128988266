import React, { useState } from "react";
import StyledBox from "views/common/components/UI/TopBar/TopBar.styles";
import { useTranslation } from "next-i18next";
import {
  Box,
  AppBar,
  Toolbar,
  Button,
  Avatar,
  Container,
  Stack,
} from "@mui/material";
import { UserDrawer } from "views/common/components/UI/UserDrawer/UserDrawer";

import { TopBarDarkButton } from "views/common/components/UI/TopBar";
import MenuIcon from "@mui/icons-material/Menu";
import { Typography500 } from "views/common/components/UI";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { IconButton, Badge } from "@mui/material";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import NavigatorLink from "views/common/components/Logical/NavigatorLink";
import PersonIcon from "@mui/icons-material/Person";
import Image from "next/image";
import { CONTACTS } from "views/common/utils/constants";
import { useSelector } from "react-redux";
import PATHS from "lib/navigation/paths";
import { DealerChangeButton } from "views/common/components/Logical";
import erLogo from "public/images/er-logo.png";
import harleyLogo from "public/images/harley-davidson-logo.png";

const TopBar = () => {
  const appState = useSelector((state) => state.globalData.appState);
  const { t } = useTranslation("common_layout");
  const phone = CONTACTS.phone;
  const user = appState.currentUser;
  const currentDealer = appState.currentDealer;
  const cartSize = appState.cartSize;
  const activeOrderId = appState.activeOrderId;
  // states
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawerState = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  // hooks

  return (
    <StyledBox>
      <AppBar position="relative" color="secondary" className="TPAppBar">
        <Container maxWidth="xl">
          <Toolbar>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Typography500
                variant="h6"
                display="inline"
                className="MBHomeLink showTABLET"
                component={NavigatorLink}
                href={PATHS.home()}
                dangerouslySetInnerHTML={{
                  __html: t("common_layout:eagleRider_erdx_logo"),
                }}
              ></Typography500>
              <Stack className="showDESKTOP">
                <Box className="TBLogos">
                  <NavigatorLink href={PATHS.home()}>
                    <Image
                      src={erLogo}
                      width={85}
                      height={80}
                      className="TBLogo"
                      alt={t("common_layout:eagleRider")}
                    />
                  </NavigatorLink>
                  <NavigatorLink href={PATHS.home()}>
                    <Image
                      src={harleyLogo}
                      width={90}
                      height={70}
                      className="TBLogo"
                      alt={t("common_layout:eagleRider")}
                    />
                  </NavigatorLink>
                </Box>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <Box className="TBDealer showDESKTOP">
                  <DealerChangeButton />
                </Box>
                <TopBarDarkButton
                  className="showDESKTOP"
                  text={phone}
                  icon={<LocalPhoneIcon />}
                  href={`tel:${phone}`}
                />
                {user && (
                  <>
                    {currentDealer && (
                      <Box className="TBCart">
                        <IconButton
                          component={NavigatorLink}
                          className="TBCartIconButton"
                          href={PATHS.orders.orderList(activeOrderId)}
                        >
                          <Badge badgeContent={cartSize}>
                            <ShoppingBagOutlinedIcon className="TBCartIcon" />
                          </Badge>
                        </IconButton>
                      </Box>
                    )}

                    <Box>
                      <Button
                        className="UDButton showDESKTOP"
                        variant="contained"
                        color="secondary"
                        onClick={toggleDrawerState}
                        edge="start"
                        size="medium"
                        endIcon={<MenuIcon />}
                        disableElevation
                      >
                        <Avatar
                          className="UDAvatar"
                          src={user.avatar_url || "/erdx/images/default.png"}
                          alt={user.first_name}
                        />
                        <Box className="UDUserInfo">
                          <span className="UDCaption">
                            {t("common_layout:welcome")}
                          </span>
                          <Typography500 variant="h6" className="UDUserName">
                            {user.first_name} {user.last_name}
                          </Typography500>
                        </Box>
                      </Button>
                      <IconButton
                        onClick={toggleDrawerState}
                        size="large"
                        className="TBUserIcon showTABLET"
                      >
                        <PersonIcon fontSize="inherit" />
                      </IconButton>
                      <UserDrawer
                        open={isDrawerOpen}
                        // setOpen={setIsDrawerOpen}
                        handleCloseDrawer={toggleDrawerState}
                      />
                    </Box>
                  </>
                )}
              </Stack>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </StyledBox>
  );
};

export { TopBar };
